import { gql, useQuery } from '@apollo/client'
import React, { useMemo, useState } from 'react'

import AssetCreateButton from '../../components/asset/create-button'
import { useTitle } from '../../components/browser'
import Button from '../../components/button'
import Layout from '../../components/layout'
import LayoutCompass from '../../components/layout/compass'
import LayoutContent from '../../components/layout/content'
import Table from '../../components/table'
import tableFragment from '../../fragments/table-media'

const GET_MEDIA_QUERY = gql`
  query GetSeries($search: String, $first: Int, $after: String) {
    records: allAssets(first: $first, search: $search, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ${tableFragment}
        }
      }
    }
  }
`

const title = "Media"
const PER_PAGE = 28

export default function SeriesIndexPage() {
  useTitle(title)
  const [searchQuery, setSearchQuery] = useState()
  const [after, setAfter] = useState()
  const { loading, error, data, fetchMore } = useQuery(GET_MEDIA_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { search: searchQuery, first: PER_PAGE },
  })

  const records = useMemo(() => data?.records?.edges?.map(({ node }) => {
    const newNode = { ...node }
    if (node?.fileDe?.key) {
      newNode["coverImage"] = node
    }
    return newNode
  }), [data])

  function handleLoadMore() {
    fetchMore({
      variables: {
        first: PER_PAGE,
        after: data?.records?.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          records: {
            edges: [...prev.records.edges, ...fetchMoreResult.records.edges],
            pageInfo: fetchMoreResult.records.pageInfo
          }
        })
      },
    })
    setAfter(data.records.pageInfo.endCursor)
  }

  if (error) {
    console.error(error)
    return <div>There was an error loading media.</div>
  }

  return (
    <Layout>
      <LayoutCompass
        title={title}
        actions={(
          <AssetCreateButton
            title="Create Asset"
            type="Asset"
            redirectOnSuccessTo="/media/:id"
          />
        )}
      />
      <LayoutContent>
        <Table
          label="asset"
          loading={loading}
          records={records}
          enableSearch
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          to='/media'
          showStatus={false}
        />
        {data?.records?.pageInfo?.hasNextPage && (
          <div className='mt-4 flex justify-center w-full'>
            <Button
              title="Load More"
              onClick={handleLoadMore}
            />
          </div>
        )}
      </LayoutContent>
    </Layout>
  )
}
