import coverImage from './cover-image'

export default `
  id
  titleDe
  fileDe {
    id
    key
    generalType
    filename(withExtension: true)
  }
  updatedAt
  updater {
    id
    displayName
  }
`
