import React, { useContext, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'

import Button from '../button'
import NotificationContext from '../../components/notifications/context'
import assetPublishFragment from '../../fragments/create-asset'
import { navigate } from 'gatsby'

const CREATE_ASSET_MUTATION = gql`
  mutation CreateAssetMutation {
    createAsset(input: {}) {
      asset {
        ${assetPublishFragment}
      }
    }
  }
`

export default function AssetCreateButton({ title="Create", type, redirectOnSuccessTo }) {
  const { addNotification } = useContext(NotificationContext)
  const [create, { loading: creating }] = useMutation(CREATE_ASSET_MUTATION, {
    onError: (err) => {
      console.error(err)
      addNotification({ title: `Error creating ${type}`, message: `The ${type} could not be created.`, type: 'error' })
    },
    onCompleted: (data) => {
      addNotification({ title: `Successfuly created ${type}`, message: `The ${type} was successfuly created.`, type: 'success' })
      navigate(redirectOnSuccessTo.replace(':id', data?.createAsset?.asset?.id))
    }
  })

  return useMemo(() => (
    <Button
      title={title}
      variant="indigo"
      className="w-full"
      loading={creating}
      onClick={create}
    />
  ), [title,, create, creating, type])
}
